import { lazy } from 'react'
import i18n from 'common/i18n'
import { lazyRetry } from '../help/utils'

const Payments = lazy(() => lazyRetry(() => import('containers/Payments')))
const DetailedBudget = lazy(() => import('containers/Payments/DetailedBudget'))
const Map = lazy(() => import('containers/Payments/Map'))
const PaymentsReport = lazy(() => import('containers/PaymentsReport'))
const ApprovePayments = lazy(() => import('containers/ApprovePayments'))
const AccountMap = lazy(() => import('containers/AccountMap'))
const AccountPayments = lazy(() => import('containers/AccountPayments'))
const DetailedBudgetAccountPayments = lazy(() => import('containers/AccountPayments/DetailedBudgetAccountPayments'))
const AccountPaymentsReport = lazy(() => import('containers/AccountPaymentsReport'))

const route = [
  {
    name: i18n.t('routes.payments.index'),
    path: '/payments',
    main: Payments,
    exact: true,
    permissions: [
      'ROLE_EDITAR_PAGAMENTOS_DISTRIBUICAO',
      'ROLE_EDITAR_VERBAS_VALIDACAO',
      'ROLE_VISUALIZAR_PAGAMENTOS_CRIADOS',
      'ROLE_VISUALIZAR_PAGAMENTOS_DISTRIBUICAO',
      'ROLE_VISUALIZAR_VERBAS_LIBERACAO',
      'ROLE_VISUALIZAR_VERBAS_VALIDACAO',
      'ROLE_EDITAR_PAGAMENTOS_CRIADOS',
      'ROLE_EDITAR_VERBAS_LIBERACAO'
    ]
  },
  {
    name: i18n.t('routes.accountPayments.index'),
    path: '/accountPayments',
    main: AccountPayments,
    exact: true,
    permissions: [
      'ROLE_EDITAR_PAGAMENTOS_DISTRIBUICAO',
      'ROLE_EDITAR_VERBAS_VALIDACAO',
      'ROLE_VISUALIZAR_PAGAMENTOS_CRIADOS',
      'ROLE_APROVA_PAGAMENTOS_TELA_GESTAO_VERBA',
      'ROLE_REPROVA_PAGAMENTOS_TELA_GESTAO_VERBA',
      'ROLE_ENVIAR_PARA_APROVACAO_PAGAMENTOS_TELA_GESTAO_VERBA'
    ]
  },
  {
    name: i18n.t('routes.payments.approve'),
    path: '/payments/approve',
    main: ApprovePayments,
    exact: true,
    permissions: [
      'ROLE_VISUALIZAR_APROVACAO_PAGAMENTO',
      'ROLE_APROVA_APROVACAO_PAGAMENTO'
    ]
  },
  {
    name: i18n.t('routes.payments.map'),
    path: '/payments/map',
    main: Map,
    exact: true,
    permissions: [
      'ROLE_VISUALIZAR_CADASTRO_BOA',
      'ROLE_EDITAR_CADASTRO_BOA'
    ]
  },
  {
    name: i18n.t('routes.accountMap.map'),
    path: '/accountMap',
    main: AccountMap,
    exact: true,
    permissions: [
      'ROLE_VISUALIZAR_CADASTRO_BOA',
      'ROLE_EDITAR_CADASTRO_BOA'
    ]
  },
  {
    path: '/payments/detailed-budget/:ids/:lotCode',
    name: i18n.t('routes.payments.detailedBudget'),
    main: DetailedBudget,
    exact: true,
    permissions: [
      'ROLE_EDITAR_VERBAS_VALIDACAO',
      'ROLE_VISUALIZAR_VERBAS_VALIDACAO',
      'ROLE_EDITAR_PAGAMENTOS_DISTRIBUICAO',
      'ROLE_VISUALIZAR_PAGAMENTOS_DISTRIBUICAO'
    ]
  },
  {
    path: '/payments/report',
    name: i18n.t('routes.payments.report'),
    main: PaymentsReport,
    exact: true,
    permission: ['ROLE_RELATORIO_PAGAMENTOS']
  },
  {
    path: '/accountPayments/detailed-budget/:idAgrupamento',
    name: i18n.t('routes.payments.detailedBudget'),
    main: DetailedBudgetAccountPayments,
    exact: true,
    permissions: [
      'ROLE_EDITAR_PAGAMENTOS_DISTRIBUICAO',
      'ROLE_EDITAR_VERBAS_VALIDACAO',
      'ROLE_VISUALIZAR_PAGAMENTOS_CRIADOS',
      'ROLE_APROVA_PAGAMENTOS_TELA_GESTAO_VERBA',
      'ROLE_REPROVA_PAGAMENTOS_TELA_GESTAO_VERBA',
      'ROLE_ENVIAR_PARA_APROVACAO_PAGAMENTOS_TELA_GESTAO_VERBA'
    ]
  },
  {
    path: '/payments/historyMovements',
    name: i18n.t('routes.accountPaymentsReport.title'),
    main: AccountPaymentsReport,
    exact: true
  }
]

export default route
