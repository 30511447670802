import { lazy } from 'react'
import i18n from 'common/i18n'

const AuditReport = lazy(() => import('containers/AuditReport'))

const route = [
  {
    name: i18n.t('routes.auditReport.index'),
    path: '/audit-report',
    main: AuditReport,
    exact: true,
    permissions: ['ROLE_VISUALIZAR_RELATORIO_ADITORIA_ADM']
  }
]
export default route
